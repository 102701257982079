import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { OrganisationUser, idleUsers, fetchAttachUser, fetchUnattachedUsers, OrganizationRole } from '../Organization/organizationUsersSlice'
import { Trans, useTranslation } from 'react-i18next'
import { Button, Form, Modal, Pagination, Select, Table, Tooltip, message } from 'antd'
import { GrUserAdmin } from 'react-icons/gr'
import { TbUsersPlus } from 'react-icons/tb'
import { ColumnsType } from 'antd/es/table'
import useSwitchStatus from '../../utils/hooks/useSwitchStatus'
import AppLayout from '../../components/AppLayout/AppLayout'
import { FaLongArrowAltLeft } from 'react-icons/fa'
import { Link } from 'react-router-dom'

const TABLE_PAGE_SIZE = 10

function UnattachedUsers() {
  const { t } = useTranslation('organization')
  const dispatch:AppDispatch = useDispatch()
  const users = useSelector((state:RootState) => state.organizationUsers.users)
  const usersTotalNumber = useSelector((state:RootState) => state.organizationUsers.usersTotalNumber)
  const [page, setPage] = useState<number>(1)
  const organizations = useSelector((state:RootState) => state.organizationList.organizations)
  const [userToAttach, setUserToAttach] = useState<OrganisationUser>()
  const [attachUserForm] = Form.useForm()
  const fetchAttachUserStatus = useSelector((state:RootState) => state.organizationUsers.fetchAttachUserStatus)
  const fetchAttachUserError = useSelector((state:RootState) => state.organizationUsers.fetchAttachUserError)

  useEffect(() => {
    updateUsers()
    return () => {
      dispatch(idleUsers())
    }
  }, [page])

  useSwitchStatus(
    fetchAttachUserStatus,
    () => {
      message.success(t('User has been attached successfully.'))
      setUserToAttach(undefined)
      attachUserForm.resetFields()
      updateUsers()
    },
    () => {
      switch (fetchAttachUserError) {
        case 'USER_NOT_FOUND':
          message.error(t('User not found.'))
          break
        case 'USER_ALREADY_ATTACHED_TO_ANOTHER_ORGANIZATION':
          message.error(t('User already attached to another organization.'))
          break
        case 'USER_ALREADY_ATTACHED_TO_THIS_ORGANIZATION':
          message.error(t('User already attached to this organization.'))
          break
        case 'NOT_ENOUGH_LICENCES':
          message.error(t("You don't have enough licences. For more licences, please contact sales support.",
        ))
          break
        default:  message.error(t('An error occured.', { ns: 'common' }))
      }
    }
  )

  function updateUsers() {
    dispatch(fetchUnattachedUsers({ page }))
  }

  function attachUser(values: any) {
    if (!userToAttach) return
    dispatch(fetchAttachUser({
        organizationId: values.organization,
        user: { email: userToAttach.email },
    }))
}
  
  const columns: ColumnsType<OrganisationUser> = [
    {
      title: t('Email'),
      render: (user: OrganisationUser) => {
        return (<>{user.organizationRole === OrganizationRole.ADMIN && <Tooltip title={t('Admin')}><GrUserAdmin/></Tooltip>} {user.email}</>)
      },
    },
    {
      title: t('First name'),
      dataIndex: 'firstName',
    },
    {
      title: t('Last name'),
      dataIndex: 'lastName',
    },
    {
      title: t('Actions'),
      render: (user: OrganisationUser) => (
        <>
          <Button type='link' onClick={() => setUserToAttach(user)}><TbUsersPlus size="1.5rem" /></Button>
        </>
      )
    },
  ]

  return (
    <AppLayout contentClassName='organizations_list'>
      <div className='mb-1rem mt-1rem ml-1rem d-flex d-flex-middle g-1em'>
        <Link to="/">
          <Button type='primary'><FaLongArrowAltLeft />&nbsp;{t('List of organizations', { ns: 'common' })}</Button>
        </Link>
      </div>

      <Table columns={columns} dataSource={users} pagination={false} />
      {usersTotalNumber > TABLE_PAGE_SIZE && (
        <Pagination
          showTotal={(total, range) => (
            <Trans
              ns="organization"
              i18nKey="TABLE_PAGINATION"
              values={{
                range0: range[0],
                range1: range[1],
                total: total,
              }}
            />
          )}
          onChange={setPage}
          current={page}
          pageSize={TABLE_PAGE_SIZE}
          total={usersTotalNumber}
          showSizeChanger={false}
        />
      )}

      <Modal
        title={t('Attach user to organization', { ns: 'common' })}
        open={!!userToAttach}
        okText={t('Attach', { ns: 'common' })}
        onOk={() => attachUserForm.submit()}
        confirmLoading={fetchAttachUserStatus === "loading"}
        cancelText={t('Cancel', { ns: 'common' })}
        onCancel={() => {
          setUserToAttach(undefined)
          attachUserForm.resetFields()
        }}
        destroyOnClose={true}
      >
        <Form
          form={attachUserForm}
          autoComplete="off"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={attachUser}
        >
          <Form.Item
            label={t('Organization', { ns: 'common' })}
            name="organization"
            rules={[{ required: true, message: t('Please select an organization') || '' }]}
          >
            <Select
              placeholder={t('Select organization', { ns: 'common' })}
              options={organizations.map(o => ({ value: o.id, label: o.name }))}
            />
          </Form.Item>
        </Form>
      </Modal>
    </AppLayout>
  )
}

export default UnattachedUsers
