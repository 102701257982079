import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ApiError } from "../../utils/Api"

interface State {
  uploadFileStatus: string,
  uploadFileError?: string
}

export const initialState:State = {
  uploadFileStatus: "idle",
}

export const fetchUploadFile = createAsyncThunk(
  'legalNotice/upload',
  async (
    { data, lang }: { data: FormData, lang: string },
    { getState }
  ) => {
    const state = getState() as { auth: { token: string }}
    const response = await fetch(`${process.env.REACT_APP_BASE_STATIC_UPLOAD_URL}/legal-notice/${lang}`, {
      method: 'POST',
      headers: {
        'Authorization': state.auth.token,
      },
      body: data
    })

    if (!response.ok) {
      const errorBody = await response.json()
      const reason = errorBody.error || "unknown_error"
      throw new ApiError(reason)
    }
  }
)

const uploadFileSlice = createSlice({
  name: 'upload-file',
  initialState,
  reducers: {
    idleUploadFileStatus: (state) => {
      state.uploadFileStatus = "idle"
    },
  },
  extraReducers: {
    [fetchUploadFile.pending.type]: (state) => {
      state.uploadFileStatus = "loading"
      state.uploadFileError = undefined
    },
    [fetchUploadFile.fulfilled.type]: (state) => {
      state.uploadFileStatus = "success"
      state.uploadFileError = undefined
    },
    [fetchUploadFile.rejected.type]: (state, action) => {
      const error = action.error
      state.uploadFileStatus = "error"
      state.uploadFileError = error.message
    },
  },
})


export default uploadFileSlice.reducer


export const {
  idleUploadFileStatus,
} = uploadFileSlice.actions