import React, { useEffect, useState } from 'react'
import './Auth.scss'
import { useDispatch, useSelector } from 'react-redux'
import { idleAttemptAuthStatus } from './authSlice'
import { AppDispatch, RootState } from '../../store'
import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Button, Form, Input, message } from 'antd'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { attemptAuth } from './authSlice'

function Auth() {
  const { i18n, t } = useTranslation('auth')
  const dispatch:AppDispatch = useDispatch()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const token = useSelector((state:RootState) => state.auth.token)
  const attemptAuthStatus = useSelector((state:RootState) => state.auth.attemptAuthStatus)
  const attemptAuthError = useSelector((state:RootState) => state.auth.attemptAuthError)

  useEffect(() => {
    switch (attemptAuthStatus) {
      case "error":
        if (attemptAuthError === "invalid_grant") {
          form.setFields([
            { name: 'email', errors: [t('Invalid email or password')] },
            { name: 'password', errors: [t('Invalid email or password')] },
          ])
        } else {
          message.error(t('Unexpected error. Please try later.'))
        }
        
        
        break
    }

    return () => {
      dispatch(idleAttemptAuthStatus())
    }
  }, [attemptAuthStatus])

  
  useEffect(() => {
    if (token) {
      navigate('/')
    }
  }, [token])


  function onFinishAuth(values: any) {
    dispatch(attemptAuth({ email: values['email'], password: values['password'] }))
  }

  return (
    <div className='AuthPage'>
      <Link to="/" className="AuthPage-logoContainer">
        <img className="logo" src={`/images/logo.svg`} />
      </Link>
      <div className='AuthPage-formContainer'>
        <Form
          form={form}
          name="basic"
          layout="vertical"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{ email: '', password: '' }}
          onFinish={onFinishAuth}
          autoComplete="off"
          disabled={attemptAuthStatus === 'loading'}
        >
          <Form.Item
            label={<span className="login-label">{t('Email')}</span>}
            name="email"
            rules={[{ required: true, message: t('Please input your email') || '' }]}
          >
            <Input prefix={<UserOutlined />} />
          </Form.Item>

          <Form.Item
            label={<span className="login-label">{t('Password')}</span>}
            name="password"
            rules={[{ required: true, message: t('Please input your password') || '' }]}
          >
            <Input.Password prefix={<LockOutlined/>} />
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            block
            loading={attemptAuthStatus === 'loading'}
          >
            {t('Log in')}
          </Button>
        </Form>
      </div>
    </div>
  )
}

export default Auth
