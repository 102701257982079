import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate } from 'react-router'
import { RootState } from '../../store'

function RequireAuth({
  children,
  invitedAccepted,
}: {
  children: any
  invitedAccepted?: boolean
}) {
  const token = useSelector((state: RootState) => state.auth.token)

  if (!token) {
    return <Navigate to="/auth" replace />
  }

  return children
}

export default RequireAuth
