import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import appLayoutEN from './trans/AppLayout/en.json'
import appLayoutFR from './trans/AppLayout/fr.json'
import organizationEN from './trans/Organization/en.json'
import organizationFR from './trans/Organization/fr.json'
import organizationListEN from './trans/OrganizationList/en.json'
import organizationListFR from './trans/OrganizationList/fr.json'
import authEN from './trans/Auth/en.json'
import authFR from './trans/Auth/fr.json'
import legalNoticeEN from './trans/LegalNotice/en.json'
import legalNoticeFR from './trans/LegalNotice/fr.json'
import cguEN from './trans/CGU/en.json'
import cguFR from './trans/CGU/fr.json'
import privacyPolicyEN from './trans/PrivacyPolicy/en.json'
import privacyPolicyFR from './trans/PrivacyPolicy/fr.json'
import commonEN from './trans/Common/en.json'
import commonFR from './trans/Common/fr.json'


export const resources = {
  en: {
    auth: authEN,
    organization: organizationEN,
    organizationList: organizationListEN,
    layout: appLayoutEN,
    legalNotice: legalNoticeEN,
    cgu: cguEN,
    privacyPolicy: privacyPolicyEN,
    common: commonEN,
  },
  fr: {
    auth: authFR,
    organization: organizationFR,
    organizationList: organizationListFR,
    layout: appLayoutFR,
    legalNotice: legalNoticeFR,
    cgu: cguFR,
    privacyPolicy: privacyPolicyFR,
    common: commonFR,
  },
} as const


i18n
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem("settingsLng") || 'fr',
    fallbackLng: 'en',
    resources,
    nsSeparator: false
  })


i18n.on("languageChanged", lng => {
  localStorage.setItem("settingsLng", lng)
})
