import { Button, Modal } from 'antd'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiBuildings } from 'react-icons/bi'
import { IoMdArrowBack } from 'react-icons/io'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AppLayout from '../../components/AppLayout/AppLayout'
import { AppDispatch } from '../../store'
import UploadCGU from './UploadCGU'
import { fetchUploadFile } from './cguSlice'

function CGU () {
  const { t } = useTranslation('cgu')
  const navigate = useNavigate()
  const dispatch:AppDispatch = useDispatch()

  const LANGUAGES = ['de', 'en', 'fr', 'it', 'uk']
  const [filePDFs, setFilePDFs] = useState<{ [lang: string]: File | null }>({ de: null, en: null, fr: null, it: null, uk: null, })
  const [openIsFileDropModal, setOpenIsFileDropModal] = useState<Boolean>(false)

  function backToOrganizationsList(): void {
    if (Object.entries(filePDFs).every(([key, value]) => value === null)) {
      navigate(`/`)
    } else {
      setOpenIsFileDropModal(true)
    }
  }

  function continueWhithSave() {
    LANGUAGES.forEach((lang) => {
      if (filePDFs[lang]) {
        const data = new FormData()
        data.append(`cgu_${lang}`, filePDFs[lang] as File)
        uploadFile(data, lang)
      }
    })
    setOpenIsFileDropModal(false)
    navigate(`/`)
  }

  function uploadFile(data : FormData, lang: string) {
    dispatch(fetchUploadFile({ data, lang }))
  }
 
  return (
    <AppLayout contentClassName='filePage'>
      <div className='mb-1rem'>
        <Button type='link' className='text-middle' onClick={() => backToOrganizationsList()}>
          <IoMdArrowBack/>
          <span>{t('Back', { ns: 'common' })}</span>
        </Button>
      </div>

      <UploadCGU 
        filePDFs={filePDFs} 
        setFilePDFs={setFilePDFs}
        uploadFile={uploadFile}
      />

      <Modal
        centered
        title={t("File backups", { ns: 'common'})}
        open={!!openIsFileDropModal}
        onCancel={() => {setOpenIsFileDropModal(false)}}
        destroyOnClose={true}
        footer={[
          <div className='d-flex d-flex-right'>
            <Button 
              onClick={() => {setOpenIsFileDropModal(false)}}
            >
              {t('Cancel', { ns: 'common'})}
            </Button>
            <Button 
              type="primary" 
              onClick={() => {
                setOpenIsFileDropModal(false)
                navigate(`/`)
              }}
            >
              {t('Continue without saving', { ns: 'common'})}
            </Button>
            <Button 
              type="primary" 
              onClick={continueWhithSave}
            >
              {t('Save', { ns: 'common'})}
            </Button>
          </div>
        ]}
      >
        <div className='d-flex d-flex-center d-flex-middle'>
          {t("The TOS contain unsaved changes.")}
        </div>
        <div className='d-flex d-flex-center d-flex-middle'>
          {t("Would you like to save your progress?", { ns: 'common'})}
        </div>
      </Modal>
    </AppLayout>    
  )
}

export default CGU