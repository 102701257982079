import React from 'react'
import AppLayout from '../../components/AppLayout/AppLayout'
import OrganizationUsers from './OrganizationUsers'
import { Link, Navigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../store'
import OrganizationGroups from './OrganizationGroups'
import { Button, Col, Row } from 'antd'
import { FaLongArrowAltLeft } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'


function Organization() {
  const { t } = useTranslation('common')
  const id = useParams()['id']
  const organization = useSelector((state:RootState) => state.organizationList.organizations.find(o => o.id === id))
  
  if (!organization) {
    return <Navigate to="/"/>
  }

  return (
    <AppLayout contentClassName='organization'>
      <div className='mb-1rem mt-1rem ml-1rem d-flex d-flex-middle g-1em'>
        <Link to="/">
          <Button type='primary'><FaLongArrowAltLeft />&nbsp;{t('List of organizations')}</Button>
        </Link>
      </div>
      <Row>
        <Col span={12} className='px-1rem'>
          <OrganizationUsers organization={organization}/>
        </Col>
        <Col span={12} className='px-1rem'>
          <OrganizationGroups organization={organization}/>
        </Col>
      </Row>
    </AppLayout>
  )
}

export default Organization