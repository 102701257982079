import React, { useEffect } from "react"
import { useTranslation } from 'react-i18next'

/**
 * @param attempt status variable (idle | loading | success)
 * @param onSuccess - action on attempt === 'success'
 * @param onError - action on attempt === 'error'
 * @param onCleanup - return to idle
 */
export default function useAttemptStatus(
    attempt: string,
    onSuccess: () => void,
    onError: () => void,
    onCleanup?: () => void
  ){
    const { t } = useTranslation('organization')

    useEffect(() => {
        switch (attempt) {
          case 'success':
            onSuccess()
            break
          case 'error':
            onError()
            break
          // default:
          //   notification.open({
          //     type: 'error',
          //     message: t("An error occurred, please try later or report this bug."),
          //   })
        }
    
        return onCleanup
      }, [attempt])
}