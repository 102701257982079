import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom"
import NotFound from "./pages/NotFound/NotFound"
import Auth from "./pages/Auth/Auth"
import RequireAuth from "./pages/Auth/RequireAuth"
import { ConfigProvider } from 'antd'
import localeFr from 'antd/lib/locale/fr_FR'
import localeEn from 'antd/lib/locale/en_GB'
import { useState } from 'react'
import AutoLogout from "./components/AutoLogout"
import CGU from './pages/CGU/CGU'
import LegalNotice from './pages/LegalNotice/LegalNotice'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import OrganizationsList from './pages/OrganizationsList/OrganizationsList'
import Organization from './pages/Organization/Organization'
import UnattachedUsers from './pages/UnattachedUsers/UnattachedUsers'

function App() {
  const currentLanguage = localStorage.getItem("settingsLng") || 'fr'
  const [locale] = useState(currentLanguage === 'fr' ? localeFr : localeEn)

  return (
    <BrowserRouter>
      <ConfigProvider locale={locale}>
        <AutoLogout>
          <Routes>
            <Route path="/auth" element={<Auth />} />
            <Route path="/" element={<RequireAuth><OrganizationsList /></RequireAuth>} />
            <Route path="/organization/:id" element={<RequireAuth><Organization /></RequireAuth>} />
            <Route path="/unattached-users" element={<RequireAuth><UnattachedUsers /></RequireAuth>} />
            <Route path="/cgu" element={<RequireAuth><CGU /></RequireAuth>} />
            <Route path="/legalNotice" element={<RequireAuth><LegalNotice /></RequireAuth>} />
            <Route path="/privacyPolicy" element={<RequireAuth><PrivacyPolicy /></RequireAuth>} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </AutoLogout>
      </ConfigProvider>
    </BrowserRouter>
  )
}

export default App
