import React, { useEffect, useState } from 'react'
import AppLayout from '../../components/AppLayout/AppLayout'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { Button, Form, Input, Modal, Spin, Table, Tag, message } from 'antd'
import { Link } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import { BiErrorCircle } from 'react-icons/bi'
import { Organization, OrganizationToCreate, fetchCreateOrganization, fetchDeleteOrganization, fetchEarliestUpcomingRdv, fetchModifyOrganization, fetchOrganizationsSummary, idleOrganizationList } from './organizationListSlice'
import { ColumnsType } from 'antd/es/table'
import useSwitchStatus from '../../utils/hooks/useSwitchStatus'


function OrganizationsList() {
  const { t } = useTranslation('organizationList')
  const dispatch:AppDispatch = useDispatch()
  const organizations = useSelector((state:RootState) => state.organizationList.organizations)
  const earliestUpcomingRdvDate = useSelector((state:RootState) => state.organizationList.earliestUpcomingRdvDate)
  const fetchSummaryStatus = useSelector((state:RootState) => state.organizationList.fetchSummaryStatus)
  const fetchCreateOrganizationStatus = useSelector((state:RootState) => state.organizationList.fetchCreateOrganizationStatus)
  const fetchCreateOrganizationError = useSelector((state:RootState) => state.organizationList.fetchCreateOrganizationError)
  const fetchModifyOrganizationStatus = useSelector((state:RootState) => state.organizationList.fetchModifyOrganizationStatus)
  const fetchModifyOrganizationError = useSelector((state:RootState) => state.organizationList.fetchModifyOrganizationError)
  const fetchDeleteOrganizationStatus = useSelector((state:RootState) => state.organizationList.fetchDeleteOrganizationStatus)
  const fetchDeleteOrganizationError = useSelector((state:RootState) => state.organizationList.fetchDeleteOrganizationError)
  const [organizationToCreate, setOrganizationToCreate] = useState<OrganizationToCreate>()
  const [organizationToModify, setOrganizationToModify] = useState<Organization>()
  const [organizationToDelete, setOrganizationToDelete] = useState<Organization>()
  const [createOrganizationForm] = Form.useForm()
  const [modifyOrganizationForm] = Form.useForm()


  useEffect(() => {
    dispatch(fetchOrganizationsSummary())
    dispatch(fetchEarliestUpcomingRdv())
    return () => {
      dispatch(idleOrganizationList())
    }
  }, [])


  useSwitchStatus(
    fetchCreateOrganizationStatus,
    () => {
      message.success(t('Organization has been created successfully.'))
      setOrganizationToCreate(undefined)
      createOrganizationForm.resetFields()
      dispatch(fetchOrganizationsSummary())
    },
    () => {
      switch (fetchCreateOrganizationError) {
        case 'LICENCE_NULL':
          message.error(t(
            'The number of licences must be higher than 0.',
          ))
          break
        default:  message.error(t('An error occured.', { ns: 'common' }))
      }
    }
  )

  useSwitchStatus(
    fetchModifyOrganizationStatus,
    () => {
      message.success(t('Organization has been modified successfully.'))
      setOrganizationToModify(undefined)
      dispatch(fetchOrganizationsSummary())
    },
    () => {
      switch (fetchModifyOrganizationError) {
        case 'ORGANIZATION_NOT_FOUND':
          message.error(t(
            'Could not modify organization. This organization does no longer exist.',
          ))
          break
        default:  message.error(t('An error occured.', { ns: 'common' }))
      }
    }
  )

  useSwitchStatus(
    fetchDeleteOrganizationStatus,
    () => {
      message.success(t('Organization has been deleted successfully.'))
      setOrganizationToDelete(undefined)
      dispatch(fetchOrganizationsSummary())
    },
    () => {
      switch (fetchDeleteOrganizationError) {
        case 'ORGANIZATION_NOT_FOUND':
          message.error(t(
            'Could not delete organization. This organization does no longer exist.',
          ))
          break
        default:  message.error(t('An error occured.', { ns: 'common' }))
      }
    }
  )

  async function createOrganization(organization: OrganizationToCreate) {
    dispatch(fetchCreateOrganization(organization))
  }


  function modifyOrganization(organization: { name: string, licences: number }) {
    dispatch(fetchModifyOrganization({
      id: organizationToModify?.id || "",
      organization,
    }))
  }


  function deleteOrganization() {
    dispatch(fetchDeleteOrganization(organizationToDelete?.id || ""))
  }


  const columns: ColumnsType<Organization> = [
    {
      title: t('Name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('Users'),
      render: (org: Organization) => (
        <>
          <Tag color={
              org.licencesTaken / org.licences >= 1 && "red"
              || org.licencesTaken / org.licences >= 0.8 && "orange"
              || "green"
            }
          >
            {org.licencesTaken} / {org.licences}
          </Tag>
          <Link to={`/organization/${org.id}`}>
            <Button 
              type='primary'
              size='small'
              className='mr-1rem'
            >
              {t('Manage')}
            </Button>
          </Link>
        </>
      )
    },
    {
      title: t('Actions'),
      render: (org: Organization) => (
        <>
          <Button 
            type='primary' 
            size='small' 
            className='mr-1rem'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setOrganizationToModify(org)
              modifyOrganizationForm.setFieldsValue(org)
            }}
          >
            {t('Modify', { ns: 'common' })}
          </Button>
          <Button 
            type='primary'
            size='small'
            danger
            className='mr-1rem'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setOrganizationToDelete(org)
            }}
          >
            {t('Delete', { ns: 'common' })}
          </Button>
        </>
      )
    },
  ]

  return (
    <AppLayout contentClassName='organizations_list'>
        <div className='mb-1rem mt-1rem ml-1rem d-flex d-flex-middle g-1em'>
          <Button type='primary' onClick={() => setOrganizationToCreate({})}>
            {t('Create a organization')}
          </Button>
          <Link to={`/unattached-users`}><Button className='ml-1rem' type='primary'>{t('List of unattached users')}</Button></Link>
          <Link to={`/cgu`}><Button className='ml-1rem' type='primary'>{t('CGU')}</Button></Link>
          <Link to={`/legalNotice`}><Button className='ml-1rem' type='primary'>{t('Legal notices')}</Button></Link>
          <Link to={`/privacyPolicy`}><Button className='ml-1rem' type='primary'>{t('Privacy policy')}</Button></Link>
        </div>

      {fetchSummaryStatus === "loading" && <Spin size='large'/>}
      {fetchSummaryStatus === "loading" && <BiErrorCircle type='error' size="2em" color='#ff4d4f' />}
      {fetchSummaryStatus === "success" && (
        <Table dataSource={organizations} columns={columns} pagination={false} />
      )}

      <Modal
        title={t('Create a organization')}
        open={!!organizationToCreate}
        okText={t('Create')}
        onOk={() => createOrganizationForm.submit() }
        confirmLoading={fetchCreateOrganizationStatus === "loading"}
        cancelText={t('Cancel', { ns: 'common' })}
        onCancel={() => {
          setOrganizationToCreate(undefined)
          createOrganizationForm.resetFields()
        }}
        destroyOnClose={true}
      >
        <Form
          form={createOrganizationForm}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={createOrganization}
          autoComplete="off"
          disabled={fetchCreateOrganizationStatus === 'loading'}
        >
          <Form.Item
            label={t('Name')}
            name="name"
            rules={[{ required: true, message: t('Please input organization name') || '' }]}
          >
            <Input/>
          </Form.Item>

          <Form.Item
            label={t('Licences')}
            name="licences"
            rules={[{ required: true, message: t('Please input number of licenses') || '' }]}
          >
            <Input type="number" min="0" />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={t("Modify organization")}
        open={!!organizationToModify}
        okText={t('Modify', { ns: 'common' })}
        onOk={() => modifyOrganizationForm.submit() }
        confirmLoading={fetchModifyOrganizationStatus === "loading"}
        cancelText={t('Cancel', { ns: 'common' })}
        onCancel={() => setOrganizationToModify(undefined)}
        destroyOnClose={true}
      >
        <Form
          form={modifyOrganizationForm}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ username: '', password: '' }}
          onFinish={modifyOrganization}
          autoComplete="off"
          disabled={fetchModifyOrganizationStatus === 'loading'}
        >
          <Form.Item
            label={t('Name')}
            name="name"
            rules={[{ required: true, message: t('Please input organization name') || '' }]}
          >
            <Input/>
          </Form.Item>

          <Form.Item
            label={t('Licences')}
            name="licences"
            rules={[{ required: true, message: t('Please input number of licenses') || '' }]}
          >
            <Input type="number" min="0" />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={t('Delete organization')}
        open={!!organizationToDelete}
        okText={t('Delete', { ns: 'common' })}
        onOk={deleteOrganization}
        confirmLoading={fetchDeleteOrganizationStatus === "loading"}
        cancelText={t('Cancel', { ns: 'common' })}
        onCancel={() => setOrganizationToDelete(undefined)}
        destroyOnClose={true}
      >
        <p>
        <Trans
          i18nKey="DELETE_ORGANIZATION"
          shouldUnescape={true}
          ns="organization"
          values={{ name: organizationToDelete?.name }}
          components={{ bold: <strong /> }}
        />
        </p>
      </Modal>
    </AppLayout>
  )
}

export default OrganizationsList
