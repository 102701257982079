import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { OrganisationUser, fetchCreateUser, fetchAttachUser, fetchDetachUser, fetchModifyUser, fetchUsers, idleUsers, OrganizationRole } from './organizationUsersSlice'
import { Trans, useTranslation } from 'react-i18next'
import { Alert, Button, Form, Input, Modal, Pagination, Select, Switch, Table, Tooltip, message } from 'antd'
import { GrUserAdmin } from 'react-icons/gr'
import { AiFillDelete, AiFillEdit } from 'react-icons/ai'
import { ColumnsType } from 'antd/es/table'
import LanguageIcon from '../../components/LanguageIcon'
import useSwitchStatus from '../../utils/hooks/useSwitchStatus'
import { Organization } from '../OrganizationsList/organizationListSlice'
import i18next from 'i18next'

const TABLE_PAGE_SIZE = 10
const { Option } = Select

interface Props {
  organization: Organization
}

function OrganizationUsers({ organization }: Props) {
  const { t } = useTranslation('organization')
  const dispatch:AppDispatch = useDispatch()
  const users = useSelector((state:RootState) => state.organizationUsers.users)
  const usersTotalNumber = useSelector((state:RootState) => state.organizationUsers.usersTotalNumber)
  const [page, setPage] = useState<number>(1)
  const groups = useSelector((state:RootState) => state.organizationGroups.groups)
  const [userToAdd, setUserToAdd] = useState(false)
  const [userToAttach, setUserToAttach] = useState<{ existingMessage?: boolean }>()
  const [userToModify, setUserToModify] = useState<OrganisationUser>()
  const [userToDelete, setUserToDelete] = useState<OrganisationUser>()
  const [addUserForm] = Form.useForm()
  const [attachUserForm] = Form.useForm()
  const [modifyUserForm] = Form.useForm()
  const fetchCreateUserStatus = useSelector((state:RootState) => state.organizationUsers.fetchCreateUserStatus)
  const fetchCreateUserError = useSelector((state:RootState) => state.organizationUsers.fetchCreateUserError)
  const fetchAttachUserStatus = useSelector((state:RootState) => state.organizationUsers.fetchAttachUserStatus)
  const fetchAttachUserError = useSelector((state:RootState) => state.organizationUsers.fetchAttachUserError)
  const fetchModifyUserStatus = useSelector((state:RootState) => state.organizationUsers.fetchModifyUserStatus)
  const fetchModifyUserError = useSelector((state:RootState) => state.organizationUsers.fetchModifyUserError)
  const fetchDeleteUserStatus = useSelector((state:RootState) => state.organizationUsers.fetchDetachUserStatus)
  const fetchDeleteUserError = useSelector((state:RootState) => state.organizationUsers.fetchDetachUserError)
  const fetchDeleteGroupStatus = useSelector((state:RootState) => state.organizationGroups.fetchDeleteGroupStatus)
  const [role, setRole] = useState<OrganizationRole>(OrganizationRole.EXTERNAL)

  const handleRoleChange = (value: OrganizationRole) => {
    setRole(value)
  }

  useEffect(() => {
    updateUsers()
    return () => {
      dispatch(idleUsers())
    }
  }, [page])

  useSwitchStatus(
    fetchCreateUserStatus,
    () => {
      message.success(t('User has been added successfully.'))
      setUserToAdd(false)
      addUserForm.resetFields()
      updateUsers()
    },
    () => {
      switch (fetchCreateUserError) {
        case 'USER_ALREADY_ATTACHED_TO_ANOTHER_ORGANIZATION':
          message.error(t(
            'Could not attach participant. He/she is already attached to another organisation.',
          ))
          break
        case 'USER_ALREADY_ATTACHED_TO_THIS_ORGANIZATION':
          message.error(t(
            'Could not attach participant. He/she is already attached to your organisation.',
          ))
          break
        case 'NOT_ENOUGH_LICENCES':
          message.error(t("You don't have enough licences. For more licences, please contact sales support.",
        ))
          break
        case 'USER_ALREADY_EXIST':
          attachUserForm.setFieldValue('email', addUserForm.getFieldValue('email'))
          setUserToAttach({ existingMessage: true })
          setUserToAdd(false)
          break
        default:  message.error(t('An error occured.', { ns: 'common' }))
      }
    }
  )

  useSwitchStatus(
    fetchModifyUserStatus,
    () => {
      message.success(t('User has been modified successfully.'))
      setUserToModify(undefined)
      updateUsers()
    },
    () => {
      switch (fetchModifyUserError) {
        case 'USER_ALREADY_EXIST':
          message.error(t('User with this email already exist.'))
          break
        default:  message.error(t('An error occured.', { ns: 'common' }))
      }
    }
  )

  useSwitchStatus(
    fetchAttachUserStatus,
    () => {
      message.success(t('User has been attached successfully.'))
      setUserToAttach(undefined)
      attachUserForm.resetFields()
      updateUsers()
    },
    () => {
      switch (fetchAttachUserError) {
        case 'USER_NOT_FOUND':
          message.error(t('User not found.'))
          break
        case 'USER_ALREADY_ATTACHED_TO_ANOTHER_ORGANIZATION':
          message.error(t('User already attached to another organization.'))
          break
        case 'USER_ALREADY_ATTACHED_TO_THIS_ORGANIZATION':
          message.error(t('User already attached to this organization.'))
          break
        case 'NOT_ENOUGH_LICENCES':
          message.error(t("You don't have enough licences. For more licences, please contact sales support.",
        ))
          break
        default:  message.error(t('An error occured.', { ns: 'common' }))
      }
    }
  )

  useSwitchStatus(
    fetchDeleteUserStatus,
    () => {
      message.success(t('User has been deleted from the organization successfully.'))
      setUserToDelete(undefined)
      updateUsers()
    },
    () => {
      switch (fetchDeleteUserError) {
        case 'USER_NOT_FOUND':
          message.error(t(
            'Could not delete participant. His/her account does not exist.',
          ))
          break
        default:  message.error(t('An error occured.', { ns: 'common' }))
      }
    }
  )

  useSwitchStatus(
    fetchDeleteGroupStatus,
    updateUsers,
    () => { }
  )

  function updateUsers() {
    dispatch(fetchUsers({ organizationId: organization.id, page }))
  }

  function createUser(values: any) {
    dispatch(fetchCreateUser({
      organizationId: organization.id,
      user: {
        email: values['email'],
        firstName: values['firstName'],
        lastName: values['lastName'],
        lang: values['lang'],
        organizationRole: values['organizationRole'] || false,
        groups: values['groups'] || [],
      }
    }))
  }

  function modifyUser(values: any) {
    if (userToModify) {
      dispatch(fetchModifyUser({
        organizationId: organization.id,
        originalEmail: userToModify.email,
        user: {
          email: values['email'],
          firstName: values['firstName'],
          lastName: values['lastName'],
          lang: values['lang'],
          organizationRole: values['organizationRole'] || false,
          groups: values['groups'] || [],
        },
      }))
    }
  }

  function attachUser(values: any) {
    dispatch(fetchAttachUser({
      organizationId: organization.id,
      user: values,
    }))
  }


  function deleteUser() {
    if (userToDelete) {
      dispatch(fetchDetachUser({
        organizationId: organization.id,
        user: { email: userToDelete?.email || "" },
      }))
    }
  }
  
  const columns: ColumnsType<OrganisationUser> = [
    {
      title: t('Email'),
      render: (user: OrganisationUser) => {
        return (<>{user.organizationRole === OrganizationRole.ADMIN && <Tooltip title={t('Admin')}><GrUserAdmin/></Tooltip>} {user.email}</>)
      },
    },
    {
      title: t('First name'),
      dataIndex: 'firstName',
    },
    {
      title: t('Last name'),
      dataIndex: 'lastName',
    },
    {
      title: t('Actions'),
      render: (user: OrganisationUser) => (
        <>
          <Button
            type='link'
            size='small' 
            onClick={() => { 
              setUserToModify(user)
              modifyUserForm.setFieldsValue({
                email: user.email,
                firstName: user.firstName,
                lastName: user.organizationRole === OrganizationRole.EXTERNAL
                  ? user.lastName?.slice(0, -6)
                  : user.lastName, //Erase (EXT) //TEmporary solution                lang: user.lang,
                organizationRole: user.organizationRole,
                groups: user.groups,
              })
            }}
          >
            <AiFillEdit/>
          </Button>
          <Button type='link' size='small' danger onClick={() => setUserToDelete(user)}><AiFillDelete/></Button>
        </>
      )
    },
  ]

  return (
    <>
      <div className='mb-1rem d-flex d-flex-middle g-1em'>
        <Button 
          type='primary'
          size='small'
          onClick={() => setUserToAdd(true)}
        >
          {t('Add an user')}
        </Button>
        <Button 
          type='primary'
          size='small'
          onClick={() => setUserToAttach({ existingMessage: false })}
        >
          {t('Attach a user')}
        </Button>
      </div>

      <Table columns={columns} dataSource={users} pagination={false} />
      {usersTotalNumber > TABLE_PAGE_SIZE && (
        <Pagination
          showTotal={(total, range) => (
            <Trans
              ns="organization"
              i18nKey="TABLE_PAGINATION"
              values={{
                range0: range[0],
                range1: range[1],
                total: total,
              }}
            />
          )}
          onChange={setPage}
          current={page}
          pageSize={TABLE_PAGE_SIZE}
          total={usersTotalNumber}
          showSizeChanger={false}
        />
      )}

      <Modal
        title={t('Add an user')}
        open={!!userToAdd}
        okText={t('Add')}
        onOk={() => addUserForm.submit()}
        confirmLoading={fetchCreateUserStatus === "loading"}
        cancelText={t('Cancel', { ns: 'common' })}
        onCancel={() => {
          setUserToAdd(false)
          addUserForm.resetFields()
        }}
        destroyOnClose={true}
      >
        <Form
          form={addUserForm}
          autoComplete="off"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={createUser}
          initialValues={{
            lang: i18next.language,
            organizationRole: OrganizationRole.USER,
          }}
        >
          <Form.Item
            label={t('Email')}
            name="email"
            rules={[
              { required: true, message: t('Please input an email') || ""},
              {
                type: 'email',
                message: t('The format of the email does not comply') || "",
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('First name')}
            name="firstName"
            rules={[{ required: true, message: t('Please input a first name') || "" },
                    { pattern: /^[^@]+$/, message: t('The @ character is not accepted') || ""} 
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('Last name')}
            name="lastName"
            rules={[{ required: true, message: t('Please input a last name') || "" },
                    { pattern: /^[^@]+$/, message: t('The @ character is not accepted') || ""}
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('Preferred language')}
            name="lang"
            rules={[{ required: true, message: t('Please select a preferred language') || "" }]}
            initialValue="en"
          >
            <Select>
              <Option value="en">
                <LanguageIcon language="en" /> <span>English</span>
              </Option>
              <Option value="fr">
                <LanguageIcon language="fr" /> <span>Français</span>
              </Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={t('Role', { ns: 'common' })}
            name="organizationRole"
            rules={[{ required: true }]}
          >
            <Select onChange={handleRoleChange}>
              <Select.Option value={OrganizationRole.ADMIN}>
                {t('Admin', { ns: 'common' })}
              </Select.Option>
              <Select.Option value={OrganizationRole.USER}>
                {t('User', { ns: 'common' })}
              </Select.Option>
              <Select.Option value={OrganizationRole.EXTERNAL}>
                {t('External', { ns: 'common' })}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={t('Groups')}
            name="groups"
          >
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder={t('Select groups')}
              options={groups.map(g => ({ value: g.id, label: g.name }))}
            />
          </Form.Item>
        </Form>
      </Modal>


      <Modal
        title={t('Modify user')}
        open={!!userToModify}
        okText={t('Modify', { ns: 'common' })}
        onOk={() => modifyUserForm.submit()}
        confirmLoading={fetchModifyUserStatus === "loading"}
        cancelText={t('Cancel', { ns: 'common' })}
        onCancel={() => setUserToModify(undefined)}
        destroyOnClose={true}
      >
        <Form
          form={modifyUserForm}
          autoComplete="off"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={modifyUser}
        >
          <Form.Item
            label={t('Email')}
            name="email"
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('First name')}
            name="firstName"
            rules={[{ required: true, message: t('Please input a first name') || "" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('Last name')}
            name="lastName"
            rules={[{ required: true, message: t('Please input a last name') || "" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t('Preferred language')}
            name="lang"
            rules={[{ required: true, message: t('Please select a preferred language') || "" }]}
            initialValue="en"
          >
            <Select>
              <Option value="en">
                <LanguageIcon language="en" /> <span>English</span>
              </Option>
              <Option value="fr">
                <LanguageIcon language="fr" /> <span>Français</span>
              </Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={t('Role', { ns: 'common' })}
            name="organizationRole"
            rules={[{ required: true }]}
          >
            <Select onChange={handleRoleChange}>
              <Select.Option value={OrganizationRole.ADMIN}>
                {t('Admin', { ns: 'common' })}
              </Select.Option>
              <Select.Option value={OrganizationRole.USER}>
                {t('User', { ns: 'common' })}
              </Select.Option>
              <Select.Option value={OrganizationRole.EXTERNAL}>
                {t('External', { ns: 'common' })}
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label={t('Groups')}
            name="groups"
          >
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder={t('Select groups')}
              options={groups.map(g => ({ value: g.id, label: g.name }))}
            />
          </Form.Item>
        </Form>
      </Modal>


      <Modal
        title={t('Attach a user')}
        open={!!userToAttach}
        okText={t('Attach')}
        onOk={() => attachUserForm.submit()}
        confirmLoading={fetchAttachUserStatus === "loading"}
        cancelText={t('Cancel', { ns: 'common' })}
        onCancel={() => {
          setUserToAttach(undefined)
          attachUserForm.resetFields()
        }}
        destroyOnClose={true}
      >
        {userToAttach?.existingMessage && (
          <Alert
            message={t('This account already exists. Do you want to attach it to this organization?')}
            className="mb-1rem"
            type="info"
          />
        )}

        <Form
          form={attachUserForm}
          autoComplete="off"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          onFinish={attachUser}
        >
          <Form.Item
            label={t('Email')}
            name="email"
            rules={[
              { required: true, message: t('Please input an email') || ""},
              {
                type: 'email',
                message: t('The format of the email does not comply') || "",
                validateTrigger: 'onSubmit',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={t('Delete user')}
        open={!!userToDelete}
        okText={t('Delete', { ns: 'common' })}
        onOk={deleteUser}
        confirmLoading={fetchDeleteUserStatus === "loading"}
        cancelText={t('Cancel', { ns: 'common' })}
        onCancel={() => setUserToDelete(undefined)}
        destroyOnClose={true}
      >
        <p>
          <Trans
            i18nKey="DELETE_USER"
            shouldUnescape={true}
            ns="organization"
            values={{
              email: userToDelete?.email,
              firstName: userToDelete?.firstName,
              lastName: userToDelete?.lastName,
              nameOrganization: organization.name
            }}
            components={{ bold: <strong /> }}
          />
        </p>
      </Modal>
    </>
  )
}

export default OrganizationUsers
