import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { ApiError, ApiFetchStatus } from "../../utils/Api"


export interface Organization {
  id      : string
  name    : string
  licences: number
  licencesTaken: number
  groupsCount: number
}


export interface OrganizationToCreate {
  name?    : string
  licences?: number
}


interface State {
  organizations: Organization[]
  earliestUpcomingRdvDate?: string
  fetchSummaryStatus: ApiFetchStatus
  fetchSummaryError?: string
  fetchCreateOrganizationStatus: ApiFetchStatus
  fetchCreateOrganizationError?: string
  fetchModifyOrganizationStatus: ApiFetchStatus
  fetchModifyOrganizationError?: string
  fetchDeleteOrganizationStatus: ApiFetchStatus
  fetchDeleteOrganizationError?: string
  fetchEarliestUpcomingRdvStatus: ApiFetchStatus
  fetchEarliestUpcomingRdvError?: string
}


const initialState: State = {
  organizations: [],
  fetchSummaryStatus: "idle",
  fetchCreateOrganizationStatus: "idle",
  fetchModifyOrganizationStatus: "idle",
  fetchDeleteOrganizationStatus: "idle",
  fetchEarliestUpcomingRdvStatus: 'idle',
}


export const fetchOrganizationsSummary = createAsyncThunk(
  'organization/fetchOrganizationsSummary',
  async (payload, { getState }) => {
    const state = getState() as any

    const response = await fetch(`${process.env.REACT_APP_BASE_USERS_URL}/organization/summary`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${state.auth.token}`,
      },
    })
    
    if (!response.ok) {
      throw new Error()
    }

    const body = await response.json()

    return body
  }
)


export const fetchCreateOrganization = createAsyncThunk(
  'organization/fetchCreateOrganization',
  async (payload: OrganizationToCreate, { getState }) => {
    const state = getState() as any

    const response = await fetch(`${process.env.REACT_APP_BASE_USERS_URL}/organization/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${state.auth.token}`,
      },
      body: JSON.stringify(payload)
    })
    
    if (!response.ok) {
      const reason = (await response.json()).message || "unknown_error"
      throw new ApiError(reason)
    }
    const body = await response.json()
    return body._id
  }
)


export const fetchModifyOrganization = createAsyncThunk(
  'organization/fetchModifyOrganization',
  async (
    { id, organization }: { id: string, organization: OrganizationToCreate },
    { getState }
  ) => {
    const state = getState() as any

    const response = await fetch(`${process.env.REACT_APP_BASE_USERS_URL}/organization/${id}/modify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `${state.auth.token}`,
      },
      body: JSON.stringify(organization)
    })
    
    if (!response.ok) {
      const reason = (await response.json()).message || "unknown_error"
      throw new ApiError(reason)
    }
  }
)


export const fetchDeleteOrganization = createAsyncThunk(
  'organization/fetchDeleteOrganization',
  async (payload: string, { getState }) => {
    const state = getState() as any

    const response = await fetch(`${process.env.REACT_APP_BASE_USERS_URL}/organization/${payload}/delete`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': state.auth.token,
      },
    })
    
    if (!response.ok) {
      const reason = (await response.json()).message || "unknown_error"
      throw new ApiError(reason)
    }
  }
)


export const fetchEarliestUpcomingRdv = createAsyncThunk(
  'organization/fetchEarliestUpcomingRdv',
  async (payload, { getState }) => {
    const state = getState() as any

    const response = await fetch(`${process.env.REACT_APP_BASE_EARLIEST_RDV_URL}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': state.auth.token,
      },
    })
    
    if (!response.ok) {
      throw new Error()
    }

    const body = await response.json()

    return body
  }
)


const organizationListSlice = createSlice({
  name: 'organizationList',
  initialState,
  reducers: {
    idleOrganizationList: (state) => {
      state.fetchSummaryStatus = "idle"
      state.fetchSummaryError = undefined
      state.fetchCreateOrganizationStatus = "idle"
      state.fetchCreateOrganizationError = undefined
      state.fetchModifyOrganizationStatus = "idle"
      state.fetchModifyOrganizationError = undefined
      state.fetchDeleteOrganizationStatus = "idle"
      state.fetchDeleteOrganizationError = undefined
      state.fetchEarliestUpcomingRdvStatus = "idle"
      state.fetchEarliestUpcomingRdvError = undefined
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchOrganizationsSummary.pending, (state) => {
        state.fetchSummaryStatus = "loading"
      })
      .addCase(fetchOrganizationsSummary.fulfilled, (state, action) => {
        state.fetchSummaryStatus = "success"
        state.organizations = action.payload
      })
      .addCase(fetchOrganizationsSummary.rejected, (state) => {
        state.fetchSummaryStatus = "error"
      })
      .addCase(fetchCreateOrganization.pending, (state) => {
        state.fetchCreateOrganizationStatus = "loading"
      })
      .addCase(fetchCreateOrganization.fulfilled, (state) => {
        state.fetchCreateOrganizationStatus = "success"
      })
      .addCase(fetchCreateOrganization.rejected, (state, action) => {
        state.fetchCreateOrganizationStatus = "error"
        state.fetchCreateOrganizationError = action.error.message
      })
      .addCase(fetchModifyOrganization.pending, (state) => {
        state.fetchModifyOrganizationStatus = "loading"
      })
      .addCase(fetchModifyOrganization.fulfilled, (state) => {
        state.fetchModifyOrganizationStatus = "success"
      })
      .addCase(fetchModifyOrganization.rejected, (state) => {
        state.fetchModifyOrganizationStatus = "error"
      })
      .addCase(fetchDeleteOrganization.pending, (state) => {
        state.fetchDeleteOrganizationStatus = "loading"
      })
      .addCase(fetchDeleteOrganization.fulfilled, (state) => {
        state.fetchDeleteOrganizationStatus = "success"
      })
      .addCase(fetchDeleteOrganization.rejected, (state) => {
        state.fetchDeleteOrganizationStatus = "error"
      })
      .addCase(fetchEarliestUpcomingRdv.pending, (state) => {
        state.fetchEarliestUpcomingRdvStatus = "loading"
      })
      .addCase(fetchEarliestUpcomingRdv.fulfilled, (state, action) => {
        state.fetchEarliestUpcomingRdvStatus = "success"
        state.earliestUpcomingRdvDate = action.payload.startDate
      })
      .addCase(fetchEarliestUpcomingRdv.rejected, (state) => {
        state.fetchEarliestUpcomingRdvError = "error"        
      })
  }
})

export const {
  idleOrganizationList,
} = organizationListSlice.actions

export default organizationListSlice.reducer
