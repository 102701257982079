import { AnyAction, combineReducers, configureStore, Reducer } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import authReducer from './pages/Auth/authSlice'
import organizationListReducer from './pages/OrganizationsList/organizationListSlice'
import organizationUsersReducer from './pages/Organization/organizationUsersSlice'
import organizationGroupsReducer from './pages/Organization/organizationGroupsSlice'
import cguReducer from './pages/CGU/cguSlice'
import legalNoticeReducer from './pages/LegalNotice/legalNoticeSlice'
import privacyPolicyReducer from './pages/PrivacyPolicy/privacyPolicySlice'

const persistConfig = {
  key: 'root',
  storage,
  blacklist: [],
}


const combinedReducer = combineReducers({
  auth: authReducer,
  organizationList: organizationListReducer,
  organizationUsers: organizationUsersReducer,
  organizationGroups: organizationGroupsReducer,
  cgu: cguReducer,
  legalNotice: legalNoticeReducer,
  privacyPolicy: privacyPolicyReducer,
})


export const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'auth/logout') {
    return combinedReducer(undefined, action)
  }
  
  return combinedReducer(state, action)
}


const persistedReducer = persistReducer(persistConfig, rootReducer)


export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST"]
      }
    }),
})

export const persistor = persistStore(store)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof combinedReducer>

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
