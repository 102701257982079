import React, { useEffect, useContext} from "react"


export default function useInterval(
  onIntervalFunction : {interval: number, action: () => void},
) {
  useEffect(() => {
    const interval = setInterval(() => {
      onIntervalFunction.action()
    }, onIntervalFunction.interval)
    return () => clearInterval(interval)
  }, [onIntervalFunction.interval, onIntervalFunction.action])
}
