import React from 'react'
import { GB, FR } from 'country-flag-icons/react/3x2'

interface Props {
  language: string
}

export default function LanguageIcon({ language }: Props) {
  switch (language) {
    case 'en':
      return <GB className="country-flag" />
    case 'fr':
      return <FR className="country-flag" />
  }

  return null
}
