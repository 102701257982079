import React from 'react'
import { Button, Menu } from 'antd'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { FiPower } from 'react-icons/fi'
import './AppLayout.scss'
import { useDispatch, useSelector } from 'react-redux'
import { Layout, Space } from 'antd'
import { logout } from '../../pages/Auth/authSlice'
import { useTranslation } from 'react-i18next'
import TokenTimer from '../TokenTimer'


const { Header, Footer, Sider, Content } = Layout;


interface Props {
  contentClassName?: string
  children: React.ReactNode
}

function AppLayout({ contentClassName, children }: Props) {
  const { t } = useTranslation('layout')
  const navigate = useNavigate()
  const dispatch = useDispatch()
  function logoutHandler() {
    dispatch(logout())
  }

  return (
    <Layout>
      <Header>
        <Link to="/" className="logo">
          <img
            className="logo-image"
            src={`/images/logo_blue.svg`}
            alt="Auxamed logo"
          />
        </Link>
        <TokenTimer/>
        <Button
          className="ml-1rem logout-button btn-secondary d-flex d-flex-middle"
          type="default"
          onClick={logoutHandler}
        >
          <FiPower className="lower-icon" />
          <span className="text-center">{t("Log out")}</span>
        </Button>
      </Header>
      <Layout>
        <Content className={contentClassName}>
          {children}
        </Content>
      </Layout>
    </Layout>
  )
}

export default AppLayout
