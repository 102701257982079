import React, { useRef, ReactNode } from 'react'
import {
  IIdleTimer,
  IdleTimerProvider,
} from 'react-idle-timer'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store'
import { logout } from '../pages/Auth/authSlice'
import AutoLogoutModal, { AutoLogoutModalInterface } from './AutoLogoutModal'

const logoutTimer = 10 * 60 * 1000 //10 mins

export default function AutoLogOut({children}: {children: ReactNode}){
  const token = useSelector((state:RootState) => state.auth.token)
  const dispatch = useDispatch()
  const timerRef = useRef<IIdleTimer>(null)
  const LogoutModalRef = useRef<AutoLogoutModalInterface>(null)

  return (
    <>
    <IdleTimerProvider
      ref={timerRef}
      timeout={logoutTimer}
      promptBeforeIdle={logoutTimer * 0.2}
      onPrompt={() => token && LogoutModalRef.current?.openModal()}
      onIdle={() => token && dispatch(logout())} 
      onAction={(e) => {
        if(e?.type !== 'focus') LogoutModalRef.current?.close()
      }}
    >
      {children}
      <AutoLogoutModal ref={LogoutModalRef}/>
    </IdleTimerProvider>

  </>

  )
}

