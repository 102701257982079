import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ApiError, ApiFetchStatus } from '../../utils/Api'
import jwt_decode from "jwt-decode"

interface User {
}


interface State extends User {
  token?: string
  tokenExpirationDate?: string
  attemptAuthStatus: ApiFetchStatus
  attemptAuthError?: string
}


const initialState: State = {
  attemptAuthStatus: "idle",
}


/**
 * Auth user with email and password.
 */
export const attemptAuth = createAsyncThunk(
  'auth/attemptAuth',
  async ({ email, password }: { email: string, password: string }) => {
    const response = await fetch(`${process.env.REACT_APP_BASE_USERS_URL}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, lang: 'fr' }),
    })

    if (!response.ok) {
      const body = await response.json()
      throw new ApiError(body.error || "unknown_error")
    }

    return await response.json()
  }
)


export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    idleAttemptAuthStatus: (state) => {
      state.attemptAuthStatus = "idle"
      state.attemptAuthError = undefined
    },
    logout: () => {
      // Handled in store.ts in rootReducer
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(attemptAuth.pending, (state, action) => {
        state.attemptAuthStatus = "loading"
        state.attemptAuthError = undefined
      })
      .addCase(attemptAuth.fulfilled, (state, action: PayloadAction<{ token: string }>) => {
        state.attemptAuthStatus = "success"
        state.attemptAuthError = undefined
        state.token = action.payload.token
        const tokenJson: any = jwt_decode(action.payload.token)
        state.tokenExpirationDate = new Date(tokenJson.exp * 1000).toISOString()
      })
      .addCase(attemptAuth.rejected, (state, action) => {
        const error = action.error
        state.attemptAuthStatus = "error"
        state.attemptAuthError = error.message
      })
  }
})

export const {
  idleAttemptAuthStatus,
  logout,
} = authSlice.actions

export default authSlice.reducer
