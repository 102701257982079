export function formatSeconds(remaining: number): string{
    switch (true){
        case (remaining <= 0): {
            return '00:00'
        }
        default: {
            const minutes = Math.floor(remaining / 60)
            const seconds = remaining % 60
            const formattedMinutes = minutes.toString().padStart(2, '0')
            const formattedSeconds = seconds.toString().padStart(2, '0')
            return ` ${formattedMinutes}:${formattedSeconds}`        
        }     
    } 
}