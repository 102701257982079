import type { RadioChangeEvent } from 'antd'
import { Button, Col, Radio, Row, UploadProps, notification } from 'antd'
import Dragger from 'antd/es/upload/Dragger'
import localeEn from 'antd/lib/locale/en_GB'
import localeFr from 'antd/lib/locale/fr_FR'
import { useEffect, useState } from 'react'
import useSwitchStatus from '../../utils/hooks/useSwitchStatus'
import { Trans, useTranslation } from 'react-i18next'
import { TiDocumentAdd } from 'react-icons/ti'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../store'
import { idleUploadFileStatus } from './cguSlice'

interface Props {
  filePDFs: {[lang: string]: File | null}
  setFilePDFs: (file: {[lang: string]: File | null}) => void
  uploadFile: (data : FormData, langCGU: string) => void
}

function UploadCGU({
  filePDFs,
  setFilePDFs,
  uploadFile,
}: Props) {
  const { t } = useTranslation('cgu')
  const dispatch:AppDispatch = useDispatch()
  const currentLanguage = localStorage.getItem("settingsLng") || 'fr'
  const [locale] = useState(currentLanguage === 'fr' ? localeFr : localeEn)
  const [langCGU, setLangCGU] = useState<string>("fr")
  const uploadFileStatus = useSelector(
    (state: RootState) => state.cgu.uploadFileStatus,
  )
  const uploadFileError = useSelector(
    (state: RootState) => state.cgu.uploadFileError,
  )
  const [iframeKey, setIframeKey] = useState(0)

  const props: UploadProps = {
    name: 'file',
    multiple: false,
    customRequest: () => {},
    onChange(info) {
      if (info.file.originFileObj?.type !== 'application/pdf') {
        notification.open({
          type: 'error',
          message: t('Only PDF files are accepted.', { ns: 'common' }),
        })
        return
      }
      setFilePDFs({...filePDFs, [langCGU]: info.file.originFileObj as File})
    },
  }

  const changeLangCGU = (lang: RadioChangeEvent) => {
    setLangCGU(lang.target.value)
  }

  useEffect(() => {
    return () => {
      if (uploadFileStatus !== 'loading' && uploadFileStatus !== 'idle'){
        dispatch(idleUploadFileStatus())
      }
    }
  }, [uploadFileStatus])

  useSwitchStatus(
    uploadFileStatus,
    () => {
      if (filePDFs[langCGU] && filePDFs[langCGU]?.name) {
        notification.open({
          type: 'success',
          message: 
            <Trans
              i18nKey="UPLOADED_FILE"
              shouldUnescape={true}
              ns="common"
              values={{ uploadedFile: filePDFs[langCGU]?.name || "" }}
            />
        })
        setIframeKey(prevKey => prevKey + 1)
        setFilePDFs({...filePDFs, [langCGU]: null})
      }
    },
    () => {
      switch (uploadFileError) {
        case 'INVALID_CATEGORY_LANGUAGE':
          notification.open({
            type: 'error',
            message: 'Error',
            description: t('Invalid category or language.'),
          })
          break
        case 'PDF_ONLY':
          notification.open({
            type: 'error',
            message: 'Error',
            description: t('Only PDF files are accepted.'),
          })
          break
        default:  
          notification.open({
            type: 'error',
            message: 'Error',
            description: t('An error occured.', { ns: 'common' }),
          })
          break
      }
    }
  )

  function cancelDrop() {
    setFilePDFs({...filePDFs, [langCGU]: null})
  }

  return (
    <>
      <Row>
        <Col span={10}>
          <div className="d-flex d-flex-center">
            <Radio.Group defaultValue={currentLanguage} buttonStyle="solid" onChange={changeLangCGU} disabled={uploadFileStatus == 'loading' ? true : false}>
              <Radio.Button className={`${filePDFs["de"] ? "warning-color" : ""}`} value="de">{t('Deutch', { ns: 'common' })}</Radio.Button>
              <Radio.Button className={`${filePDFs["en"] ? "warning-color" : ""}`} value="en">{t('English', { ns: 'common' })}</Radio.Button>
              <Radio.Button className={`${filePDFs["fr"] ? "warning-color" : ""}`} value="fr">{t('French', { ns: 'common' })}</Radio.Button>
              <Radio.Button className={`${filePDFs["it"] ? "warning-color" : ""}`} value="it">{t('Italian', { ns: 'common' })}</Radio.Button>
              <Radio.Button className={`${filePDFs["uk"] ? "warning-color" : ""}`} value="uk">{t('Ukrainian', { ns: 'common' })}</Radio.Button>
            </Radio.Group>
          </div>

          <div className="d-flex d-flex-center">
            <div className="w-90">
              <Dragger {...props} showUploadList={false} className="d-flex mt-1rem" accept='application/pdf'>
                <p className="ant-upload-text pt-1rem pb-1rem pl-2rem pr-2rem">
                  <strong>
                  {langCGU == "de" && (
                    t('Upload a more recent version of the Terms of Service in deutch')
                  )}
                  {langCGU == "en" && (
                    t('Upload a more recent version of the Terms of Service in english')
                  )}
                  {langCGU == "fr" && (
                    t('Upload a more recent version of the Terms of Service in french')
                  )}
                  {langCGU == "it" && (
                    t('Upload a more recent version of the Terms of Service in italian')
                  )}
                  {langCGU == "uk" && (
                    t('Upload a more recent version of the Terms of Service in ukrainian')
                  )}
                  </strong>
                </p>
                <div className="d-flex d-flex-middle d-flex-center pb-1rem">
                  <TiDocumentAdd size="2rem" />
                  <p className="ant-upload-hint mb-0">
                    {t(
                        'Only PDF files are accepted.', { ns: 'common' }
                    )}
                  </p>
                </div>
              </Dragger>
            </div>
          </div>
        </Col>
        <Col span={14}>
          <h2 className="d-flex d-flex-middle d-flex-center pb-1rem">
            {filePDFs[langCGU] ? (
              <span className='new-file'><strong>{t('New file to send: ', { ns: 'common' })}</strong>{` ${filePDFs[langCGU]?.name}`}</span>
            ) : (
              <strong>{t('File actually on the server', { ns: 'common' })}</strong>
            )}
          </h2>
          <div className="d-flex d-flex-middle d-flex-center">
            {filePDFs[langCGU] ? (
              <iframe 
                key={iframeKey}
                src={URL.createObjectURL(filePDFs[langCGU] as File)} 
                width="90%" 
                height="700rem"
              />
            ) : (
              <iframe 
                key={iframeKey}
                src={`${process.env.REACT_APP_BASE_STATIC_URL}/cgu_${langCGU}.pdf`} 
                width="90%" 
                height="700rem"
              />
            )}
          </div>
        </Col>
      </Row>
      {filePDFs[langCGU] && (
        <Row className='w-100 sticky-row'>
          <div className="w-100 d-flex d-flex-center ">
            <Button onClick={cancelDrop}>
              {t('Cancel', { ns: 'common' })}
            </Button>
            <div className='ml-1rem'>
              <Button
                type="primary" 
                onClick={async () => {
                  const data = new FormData()
                  data.append(`file`, filePDFs[langCGU] as File)
                  uploadFile(data, langCGU)
                }}
              >
                {t('Update Terms of Service')}
              </Button>
            </div>
          </div>
        </Row>
      )}
    </>
  )
}

export default UploadCGU